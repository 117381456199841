import EventEmitter from './EventEmitter.js'

export default class Scroll extends EventEmitter
{
    constructor(margin, dragDirection, scrollSpeed = 1)
    {
        super()

        // Setup
        this.dragDirection = dragDirection
        this.margin = margin
        this.scrollSpeed = scrollSpeed
        this.scrolling = false
        this.wheelScrolling = false
        this.timer = null
        this.scroll = 0
        this.scrollTarget = 0
        this.prevScroll = 1
        this.currentScroll = 0
        this.snapValue = 0

        // Scroll event
        window.addEventListener('wheel', (e) => 
        {
            if(this.timer !== null) {
                clearTimeout(this.timer)      
            }

            this.timer = setTimeout(() => {
                this.wheelScrolling = false
            }, 150)

            this.scrolling = true
            this.wheelScrolling = true
            this.scrollTarget = e.wheelDelta * 0.003 * this.scrollSpeed
        })

        // Arrow event
        window.addEventListener('keydown', (e) =>
        {
            this.scrolling = true

            switch (e.key) 
            {
                case 'ArrowUp':
                    this.scrollTarget = 0.425 * this.scrollSpeed;
                    break;
                case 'ArrowDown':
                    this.scrollTarget = -0.425 * this.scrollSpeed;
                    break;
                case 'ArrowLeft':
                    this.scrollTarget = 0.425 * this.scrollSpeed;
                    break;
                case 'ArrowRight':
                    this.scrollTarget = -0.425 * this.scrollSpeed;
                    break;
            }
        })

        // Drag event
        this.mouseDownHandler = () =>
        {
            window.addEventListener('mousemove', this.mouseMoveHandler)
            window.addEventListener('mouseup', this.mouseUpHandler)
        }

        this.mouseMoveHandler = (e) =>
        {
            this.scrolling = true
            if(this.scrollSpeed === 1)
            {
                if(this.dragDirection === 'x')
                {
                    this.scrollTarget += e.movementX * 0.001
                }
                else if(this.dragDirection === 'y')
                {
                    this.scrollTarget += e.movementY * 0.001
                }
            }
            else
            {
                if(this.dragDirection === 'x')
                {
                    this.scrollTarget += e.movementX * 0.001 * this.scrollSpeed * 4
                }
                else if(this.dragDirection === 'y')
                {
                    this.scrollTarget += e.movementY * 0.001 * this.scrollSpeed * 4
                }
            }
        }

        this.mouseUpHandler = () =>
        {
            window.removeEventListener('mousemove', this.mouseMoveHandler)
            window.removeEventListener('mouseup', this.mouseUpHandler)
        }

        window.addEventListener('mousedown', this.mouseDownHandler)
    }

    lerp(a, b, t) 
    {
        return a * (1-t) + b * t
    }

    scrollTo(valueToScrollTo, duration)
    {
        this.currentScroll = this.lerp(this.currentScroll, valueToScrollTo, duration)
    }

    update()
    {
        this.snapValue = Math.round(this.currentScroll / this.margin) * this.margin
        this.scroll += (this.scrollTarget - this.scroll)
        this.scroll *= 0.9
        this.scrollTarget *= 0.9
        this.currentScroll += this.scroll
        if(this.scroll > -0.0001 && this.scroll < 0.0001 && !this.wheelScrolling)
        {
            this.scrollTo(this.snapValue, 0.2)
            this.scrolling = false
        }
    }
}