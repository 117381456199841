import * as THREE from 'three'
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Raycaster from '../Utils/Raycaster.js'
import Scroll from '../Utils/Scroll.js'
import Image from './Image.js'
import vertexShader from '../shaders/slider/vertex.glsl'
import fragmentShader from '../shaders/slider/fragment.glsl'

export default class Slider
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        gsap.registerPlugin(CustomEase)
        CustomEase.create('customEase', '0.23, 1, 0.32, 1')
        this.sliderImages = []
        this.sliderImagesMesh = []
        this.sliderImagesSizes = 
        {
            width: 2.5, 
            height: 2.5*(4/3)
        }
        this.sliderImagesMargin = 4
        this.sliderWidth = 0
        this.executed = false
        this.clickedImage = null
        this.imagesPos = {}
        this.isAboutOpen = false
        this.scroll = new Scroll(this.sliderImagesMargin, 'x')
        this.preventScroll = false
        this.ended = false
        this.preventClicking = false

        // Setup
        this.sliderWidth = 6 * this.sliderImagesMargin
        for(let i = 0; i < 6; i++)
        {
            let textureName
            let imageIndex

            if(i >= 3)
            {
                textureName = `image${i - 3}Texture`
                imageIndex = i - 3
            }
            else
            {
                textureName = `image${i}Texture`
                imageIndex = i
            }

            this.sliderImages[i] = new Image(this.resources.items[textureName], this.sliderImagesSizes, this.sliderImagesMargin, vertexShader, fragmentShader, imageIndex, !this.experience.isAboutOpen)
            this.sliderImagesMesh[i] = this.sliderImages[i].mesh
        }

        this.raycaster = new Raycaster(this.sliderImagesMesh, this.camera.instance)
        this.raycasterLeft = new Raycaster(this.sliderImagesMesh, this.camera.instance, new THREE.Vector2(-0.65, 0))
        this.raycasterMid = new Raycaster(this.sliderImagesMesh, this.camera.instance, new THREE.Vector2(0, 0))
        this.raycasterRight = new Raycaster(this.sliderImagesMesh, this.camera.instance, new THREE.Vector2(0.65, 0))
    }

    destroy()
    {
        this.experience.destroy()
    }

    update()
    {
        if(!this.executed && !this.preventScroll)
        {
            this.scroll.update()
        }

        // Checking if images are rendered before updating them
        if(this.sliderImages && !this.isAboutOpen)
        {
            if(this.raycasterLeft && this.raycasterMid && this.raycasterRight)
            {
                this.raycasterLeft.update()
                this.raycasterMid.update()
                this.raycasterRight.update()

                if(this.raycasterLeft.currentIntersect && this.raycasterMid.currentIntersect && this.raycasterRight.currentIntersect && !this.scroll.scrolling && !this.executed)
                {
                    this.imagesPos[this.raycasterLeft.currentIntersect.object.material.uniforms.uImageIndex.value] = Math.round(this.raycasterLeft.currentIntersect.object.position.x)
                    this.imagesPos[this.raycasterMid.currentIntersect.object.material.uniforms.uImageIndex.value] = Math.round(this.raycasterMid.currentIntersect.object.position.x)
                    this.imagesPos[this.raycasterRight.currentIntersect.object.material.uniforms.uImageIndex.value] = Math.round(this.raycasterRight.currentIntersect.object.position.x)
                }
            }

            // Checking if the raycaster has been created before updating it
            if(this.raycaster)
            {
                // Updating the raycaster
                this.raycaster.update()

                this.sliderImages.forEach((image) => 
                {
                    if(this.raycaster.currentIntersect && !this.executed)
                    {
                        if(image.mesh === this.raycaster.currentIntersect.object)
                        {
                            image.mouse.x = this.raycaster.currentIntersect.uv.x
                            image.mouse.y = -(this.raycaster.currentIntersect.uv.y - 1)

                            gsap.to(image.material.uniforms.uIsColored, {
                                value: 1.0
                            })
                            gsap.to(image.material.uniforms.uOpacity, {
                                value: 1.0
                            })
                        }
                    }
                    else if(!this.executed)
                    {
                        gsap.to(image.material.uniforms.uIsColored, {
                            value: 0.0
                        })
                        gsap.to(image.material.uniforms.uOpacity, {
                            value: 0.5
                        })
                    }
                })

                // Checking if an object has been clicked
                if(!this.preventClicking && !this.scroll.scrolling)
                {
                    if(this.raycaster.objectClicked && !this.executed)
                    {
                        // Prevent looping
                        this.executed = true

                        // Looping through every Image object
                        this.sliderImages.forEach((image) => 
                        {
                            // Changing the opacity of all sliderImages except the one clicked
                            if(image.mesh !== this.raycaster.objectClicked.object)
                            {
                                gsap.to(image.material.uniforms.uOpacity, {
                                    value: 0.0,
                                    ease: 'customEase',
                                    onUpdate: () => 
                                    {
                                        this.clickedImage.update()
                                    },
                                    onComplete: () =>
                                    {
                                        image.geometry.dispose()

                                        this.clickedImage.mesh.material.uniforms.uIsColored.value = 1.0

                                        gsap.to(this.clickedImage.mesh.material.uniforms.uMovement, {
                                            value: 0.0,
                                            duration: .2
                                        })

                                        gsap.to(this.raycaster.objectClicked.object.position, {
                                            x: -((this.camera.width / 2) - (this.sliderImagesSizes.width / 2) - ((this.camera.width / 100) * (407 / 15.36))),
                                            z: 2,
                                            ease: 'customEase',
                                            duration: .5,
                                            onComplete : () =>
                                            {
                                                this.ended = true
                                                this.clickedImage.material.uniforms.uOpacity.value = 0
                                                this.clickedImage.mesh.position.x = 0
                                                this.clickedImage.mesh.position.z = 0
                                                this.raycaster.objectClicked = null
                                            }
                                        })
                                    }
                                })
                            }
                            else 
                            {
                                this.clickedImage = image
                                this.clickedImageIndex = image.material.uniforms.uImageIndex.value
                            }
                        })
                    }
                }
                else
                {
                    this.raycaster.objectClicked = null
                }

                // Looping through every Image object to create the infinite scroll
                if(!this.executed)
                {
                    this.sliderImages.forEach((image, index) => 
                    {
                        image.mesh.position.x = (index * this.sliderImagesMargin + this.scroll.currentScroll + 10000 * this.sliderWidth) % this.sliderWidth - (this.sliderImagesMargin * 3)
                        gsap.to(image.material.uniforms.uMovement, {
                            value: this.scroll.scroll
                        })
                        image.update()
                    })
                }
            }
        }
    }
}