export default [
    {
        name: 'image0Texture',
        type: 'texture',
        path: 'textures/images/image0.jpg'
    },
    {
        name: 'image1Texture',
        type: 'texture',
        path: 'textures/images/image1.jpg'
    },
    {
        name: 'image2Texture',
        type: 'texture',
        path: 'textures/images/image2.jpg'
    },
    {
        name: 'pictureTexture',
        type: 'texture',
        path: 'textures/about/image.jpg'
    }
]