import * as THREE from 'three'
import Experience from './Experience.js'

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.cameraFov = 45
        this.fovInRadians = (this.cameraFov * Math.PI) / 180;
        this.height = Math.abs(
            5 * Math.tan(this.fovInRadians / 2) * 2,
        )
        this.width = this.height * this.sizes.aspectRatio

        this.setInstance()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(this.cameraFov, this.sizes.width / this.sizes.height, 0.1, 100)
        this.instance.position.set(0, 0, 7)
        this.scene.add(this.instance)
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }
}