import * as THREE from 'three'
import { gsap } from 'gsap'
import Experience from '../Experience.js'
import Scroll from '../Utils/Scroll.js'

export default class Image
{
    constructor(imageName, imageSize, imageMargin, vertexShader, fragmentShader, imageIndex, intro = false)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.intro = intro
        this.vertexShader = vertexShader
        this.fragmentShader = fragmentShader
        this.imageIndex = imageIndex
        this.scroll = new Scroll(imageMargin)
        this.imageHoverCoord = 
        {
            x: 0,
            y: 0
        }
        this.mouse = 
        {
            x: 0,
            y: 0,
            prevX: 0,
            prevY: 0,
            vX: 0,
            vY: 0
        }
        this.imageSize = imageSize

        this.setGeometry(imageSize)
        this.setTextures(imageName)
        this.setMaterial()
        this.setMesh()
    }

    setGeometry(imageSize)
    {
        this.geometry = new THREE.PlaneGeometry(imageSize.width, imageSize.height, 32, 32)
    }

    setTextures(imageName)
    {
        if(imageName === '')
        {
            return
        }
        this.textures = {}
        this.textures.needsUpdate = true

        this.textures = imageName
        this.textures.encoding = THREE.LinearEncoding
    }

    onMouseMove()
    {
        this.mouse.vX = this.mouse.x - this.mouse.prevX
        this.mouse.vY = this.mouse.y - this.mouse.prevY

        this.mouse.prevX = this.mouse.x
        this.mouse.prevY = this.mouse.y
    }

    regenerateGrid() 
    {
        this.gridSize = 15

        const width = this.gridSize
        const height = this.gridSize

        const size = width * height
        const data = new Float32Array(4 * size)
        const color = new THREE.Color(0xffffff)

        const r = Math.floor(color.r * 255)
        const g = Math.floor(color.g * 255)
        const b = Math.floor(color.b * 255)

        // Intro
        if(this.intro)
        {
            for (let i = 0; i < size; i++) 
            {
                let r = Math.random() * 255 - 125
                let r1 = Math.random() * 255 - 125

                const stride = i * 4

                data[stride] = r
                data[stride + 1] = r1
                data[stride + 2] = r
                data[stride + 3] = 255
            }
        }

        this.dataTexture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat, THREE.FloatType)

        this.dataTexture.magFilter = this.dataTexture.minFilter = THREE.NearestFilter

        if (this.material) 
        {
            this.material.uniforms.uDataTexture.value = this.dataTexture
            this.material.uniforms.uDataTexture.value.needsUpdate = true
        }
    }

    setMaterial()
    {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            vertexShader: this.vertexShader,
            fragmentShader: this.fragmentShader,
            uniforms:
            {
                uImageIndex: { value: this.imageIndex },
                uTexture: { value: this.textures },
                uNewTexture: { value: {} },
                uDataTexture: { value: this.dataTexture },
                uUpdateTexture: { value: 0.0 },
                uIsColored: { value: 0.0 },
                uOpacity: { value: 0.0 },
                uMovement: { value: 0.0 },
                uTime: { value: 0.0 },
                uRes: { value: new THREE.Vector4(window.innerWidth, window.innerHeight, (window.innerWidth / window.innerHeight) * (3/4), (window.innerWidth / window.innerHeight) / (4/3)) }
            },
            defines: 
            {
                PR: Math.min(window.devicePixelRatio.toFixed(1), 2)
            }
        })
    }

    setMesh()
    {
        this.regenerateGrid()
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.scene.add(this.mesh)
    }

    updateDataTexture() {
        let data = this.dataTexture.image.data
        for (let i = 0; i < data.length; i += 4) {
            data[i] *= 0.9
            data[i + 1] *= 0.9
        }
    
        let gridMouseX = this.gridSize * this.mouse.x
        let gridMouseY = this.gridSize * (1 - this.mouse.y)
        let maxDist = this.gridSize * 0.15
        let aspect = this.imageSize.height / this.imageSize.width
    
        for (let i = 0; i < this.gridSize; i++) {
            for (let j = 0; j < this.gridSize; j++) {
        
                let distance = ((gridMouseX - i) ** 2) / aspect + (gridMouseY - j) ** 2
                let maxDistSq = maxDist ** 2
        
                if (distance < maxDistSq) {
        
                let index = 4 * (i + this.gridSize * j)
        
                let power = maxDist / Math.sqrt(distance)
                power = Math.max(0, Math.min(power, 10))

                data[index] += 0.15 * 100 * this.mouse.vX * power
                data[index + 1] -= 0.15 * 100 * this.mouse.vY * power
                }
            }
        }
    
        this.mouse.vX *= 0.9
        this.mouse.vY *= 0.9
        this.dataTexture.needsUpdate = true
    }

    updateTexture(texture, imageIndex)
    {
        this.material.uniforms.uImageIndex.value = imageIndex
        this.textures = texture
        this.material.uniforms.uNewTexture.value = this.textures
        gsap.to(this.material.uniforms.uUpdateTexture, {
            value: 1.0,
            onComplete: () =>
            {
                this.material.uniforms.uTexture.value = this.textures
                this.material.uniforms.uUpdateTexture.value = 0
            }
        })
    }

    update()
    {
        this.material.uniforms.uTime.value = this.time.elapsed * 0.01
        this.updateDataTexture()
        this.onMouseMove()
    }
}