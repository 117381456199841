import * as THREE from 'three'
import { gsap } from 'gsap'
import { CustomEase } from 'gsap/all'
import ProjectsDescriptions from '../projectsDescriptions.json'
import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'
import Splitting from 'splitting'
import Experience from '../Experience.js'
import Raycaster from '../Utils/Raycaster.js'
import Scroll from '../Utils/Scroll.js'
import Image from './Image.js'
import vertexShaderSlider from '../shaders/project/projectSlider/vertex.glsl'
import fragmentShaderSlider from '../shaders/project/projectSlider/fragment.glsl'
import vertexShaderImage from '../shaders/project/projectImage/vertex.glsl'
import fragmentShaderImage from '../shaders/project/projectImage/fragment.glsl'

export default class Projects
{
	constructor(imagesPos, selectedProject)
	{
		// Setup
		this.projectTextAnimation = true
		this.experience = new Experience()
		this.scene = this.experience.scene
		this.resources = this.experience.resources
		this.camera = this.experience.camera
		gsap.registerPlugin(CustomEase)
		CustomEase.create('customEase', '0.23, 1, 0.32, 1')
		this.isAboutOpen = false
		this.imageSize = {
			width: 2.5,
			height: 2.5 * (4/3)
		}
		this.projectsImages = []
		this.projectsImagesMesh = []
		this.projectsImagesSizes = {
			width: (this.camera.width / 100) * (143 / 15.36), 
			height: (this.camera.width / 100) * (143 / 15.36) * (3 / 4)
		}
		this.projectsImagesMargin = this.projectsImagesSizes.height + ((this.camera.height / 100) * (12 / 7.14))
		this.sliderHeight = 0
		this.clickedImage = null
		this.projectMargin = (this.camera.width / 100) * 8.59375
		this.numberOfProjects = 3
		this.ending = false
		this.ended = false
		this.imagesPos = imagesPos

		this.selectedProject = selectedProject
		if(this.selectedProject === 0)
		{
			this.selectedProjectIndex = 6
			this.selectedProjectIndexCalc = 0
		}
		else if(this.selectedProject === 1)
		{
			this.selectedProjectIndex = 5
			this.selectedProjectIndexCalc = 2
		}
		else
		{
			this.selectedProjectIndex = 7
			this.selectedProjectIndexCalc = -2
		}

		this.scroll = new Scroll(this.projectsImagesMargin, 'y', 0.175)

		// Scene Setup
		this.image = new Image(this.resources.items[`image${this.selectedProject}Texture`], this.imageSize, 0, vertexShaderImage, fragmentShaderImage, this.selectedProject)
		this.image.material.uniforms.uIsColored.value = 1
		this.image.material.uniforms.uOpacity.value = 1
		this.image.mesh.position.x = -((this.camera.width / 2) - (this.imageSize.width / 2) - ((this.camera.width / 100) * (407 / 15.36))) 
		this.image.mesh.position.z = 2

		this.sliderHeight = (this.numberOfProjects * 4) * this.projectsImagesMargin
		for(let i = 0; i < (this.numberOfProjects * 4); i++)
		{
			let textureName
			let imageIndex

			if(Math.floor(i / this.numberOfProjects) >= 1)
			{
				textureName = `image${i - this.numberOfProjects * Math.floor(i / this.numberOfProjects)}Texture`
				imageIndex = i - this.numberOfProjects * Math.floor(i / this.numberOfProjects)
			}
			else 
			{
				textureName = `image${i}Texture`
				imageIndex = i
			}

			this.projectsImages[i] = new Image(this.resources.items[textureName], this.projectsImagesSizes, this.projectsImagesMargin, vertexShaderSlider, fragmentShaderSlider, imageIndex)
			this.projectsImagesMesh[i] = this.projectsImages[i].mesh
		}

		this.projectsImages.forEach((image, index) => 
		{
			image.mesh.position.x = -((this.camera.width / 2) - (this.projectsImagesSizes.width / 2) - this.projectMargin)
			image.mesh.position.z = 2
			if(this.selectedProjectIndex + this.selectedProjectIndexCalc === index)
			{
				gsap.to(image.material.uniforms.uOpacity, {
					value: 1.0,
					duration: 1,
					ease: 'customEase'
				})
				gsap.to(image.material.uniforms.uIsColored, {
					value: 1.0,
					duration: 1,
					ease: 'customEase'
				})
			}
			else
			{
				gsap.to(image.material.uniforms.uOpacity, {
					value: .5,
					duration: 1,
					ease: 'customEase',
					onComplete: () =>
					{
						if(index === this.projectsImages.length - 1)
						{
							this.raycasterProjectImage = new Raycaster([this.image.mesh], this.camera.instance)
							this.raycaster = new Raycaster(this.projectsImagesMesh, this.camera.instance)
							this.raycasterCenterImage = new Raycaster(this.projectsImagesMesh, this.camera.instance, new THREE.Vector2(-0.7, 0))
						}
					}
				})
			}
		})

		this.setProjectsTexts()
		gsap.fromTo(`.project-${this.selectedProject} .char-text`, 
		{
			y: '150%'
		},
		{
			y: '0',
			ease: 'customEase',
			stagger: {
				amount: .3
			}
		})
		gsap.fromTo(`.project-${this.selectedProject} .word-text`, 
		{
			y: '150%'
		},
		{
			y: '0',
			ease: 'customEase',
			stagger: {
				amount: .3
			}
		})
		gsap.fromTo('.project-link .char-text', 
		{
			y: '150%'
		},
		{
			y: '0',
			ease: 'customEase',
			stagger: {
				amount: .3
			}
		})

		// Project link interaction
		this.projectLinkTimeline = gsap.timeline({paused: true})

		this.projectLinkTimeline.to('.project-links .project-links-wrapper .project-link:first-of-type .char', {
			y: '-100%',
			ease: 'customEase',
			stagger: {
				amount: .3
			}
		}, 0)

		this.projectLinkTimeline.to('.project-links .project-links-wrapper .project-link:last-of-type .char', {
			y: '-100%',
			delay: .15,
			ease: 'customEase',
			stagger: {
				amount: .3
			}
		}, 0)

		document.querySelector('.project-links').addEventListener('mouseover', () => {
			this.projectLinkTimeline.play()
		})

		document.querySelector('.project-links').addEventListener('mouseleave', () => {                                
			this.projectLinkTimeline.reverse()
		})
	}

	setProjectsTexts()
	{
		document.querySelector('nav').insertAdjacentHTML(
			'afterend',
			`<div class="projects"><div class="project-links"><div class="project-links-wrapper"><a class="project-link" href="${ProjectsDescriptions.projectsDescriptions[this.selectedProject].link}" target="_blank">Visit site</a><a class="project-link" href="${ProjectsDescriptions.projectsDescriptions[this.selectedProject].link}" target="_blank">Visit site</a></div></div></div>`
		)
		ProjectsDescriptions.projectsDescriptions.forEach((project, index) =>
		{
			document.querySelector('.projects').insertAdjacentHTML(
				'afterbegin',
				`<div class="project project-${index}"><h2 class="project-name">${project.name}</h2><p class="project-desc">${project.content}</p></div>`
			)
			Array.from(Splitting({target: `.project-${index} .project-name`, by: 'chars'})[0].chars).forEach((el) =>
			{
				el.innerHTML = `<span class="char-text">${el.innerHTML}</span>`
			})
			Array.from(Splitting({target: `.project-${index} .project-desc`, by: 'words'})[0].words).forEach((el) =>
			{
				el.innerHTML = `<span class="word-text">${el.innerHTML}</span>`
			})
			Array.from(Splitting({target: `.projects .project-link`, by: 'chars'})[0].chars).forEach((el) =>
			{
				el.innerHTML = `<span class="char-text">${el.innerHTML}</span>`
			})
		})
	}

	end()
	{
		this.ending = true

		gsap.to(`.project-${this.selectedProject} .char-text`, {
			y: '-100%',
			ease: 'customEase',
			duration: .3
		})

		gsap.to(`.project-${this.selectedProject} .word-text`, {
			y: '-100%',
			ease: 'customEase',
			duration: .3
		})

		gsap.to('.project-link .char-text', {
			y: '-100%',
			ease: 'customEase',
			duration: .3,
			onComplete: () =>
			{
				document.querySelector('.projects').remove()
			}
		})

		this.projectsImages.forEach((image) =>
		{
			gsap.to(image.material.uniforms.uOpacity, {
				value: 0.0, 
				ease: 'customEase',
				duration: .3
			})
		})

		gsap.to(this.image.material.uniforms.uOpacity, {
			value: 0.5,
			ease: 'customEase',
			duration: .5
		})

		gsap.to(this.image.material.uniforms.uIsColored, {
			value: 0.0,
			ease: 'customEase',
			duration: .5
		})

		gsap.to(this.image.mesh.position, {
			x: this.imagesPos[this.selectedProject],
			z: 0,
			ease: 'customEase',
			delay: .3,
			duration: .5,
			onComplete: () =>
			{
				this.ended = true
			}
		})
	}

	destroy()
	{
		this.experience.destroy()
	}

	update()
	{
		if(!this.ended && !this.isAboutOpen)
		{
			if(this.image)
			{
				if(this.raycasterProjectImage)
				{
					this.raycasterProjectImage.update()

					if(this.raycasterProjectImage.currentIntersect)
					{
						this.image.mouse.x = this.raycasterProjectImage.currentIntersect.uv.x
						this.image.mouse.y = -(this.raycasterProjectImage.currentIntersect.uv.y - 1)
					}
				}

				this.image.update()
			}

				// Checking if images are rendered before updating them
			if(this.projectsImages)
			{
				// Checking if the raycaster has been created before updating it
				if(this.raycaster)
				{
					// Updating the raycaster
					this.raycaster.update()

					if(this.raycaster.objectClicked)
					{
						if(this.clickedImage)
						{
							if(this.clickedImage !== this.raycaster.objectClicked)
							{
								this.raycaster.objectClicked = this.clickedImage
							}
						}
						else
						{
							this.clickedImage = this.raycaster.objectClicked
						}

						gsap.to(this.scroll, {
							currentScroll: `+=${this.raycaster.objectClicked.object.position.y}`,
							onStart: () =>
							{
								this.scroll.scrolling = true
								if(this.raycaster.objectClicked && this.raycasterCenterImage.currentIntersect && this.image.material.uniforms.uImageIndex.value !== this.raycaster.objectClicked.object.material.uniforms.uImageIndex.value && !this.scroll.scrolling)
								{
									if(this.projectTextAnimation)
									{
										gsap.to(`.project-${this.selectedProject} .char-text`, {
											y: '-150%',
											ease: 'customEase',
											stagger: {
												amount: .3
											}
										})
										gsap.to(`.project-${this.selectedProject} .word-text`, {
											y: '-150%',
											ease: 'customEase',
											stagger: {
												amount: .3
											},
											onStart: () =>
											{
												this.prevSelectedProject = this.selectedProject
												this.selectedProject = this.raycaster.objectClicked.object.material.uniforms.uImageIndex.value
												gsap.fromTo(`.project-${this.selectedProject} .char-text`, 
												{
													y: '150%'
												},
												{
													y: '0',
													ease: 'customEase',
													stagger: {
														amount: .3
													}
												})
												gsap.fromTo(`.project-${this.selectedProject} .word-text`, 
												{
													y: '150%'
												},
												{
													y: '0',
													ease: 'customEase',
													stagger: {
														amount: .3
													}
												})
											}
										})
									}
									this.projectTextAnimation = false

									this.image.updateTexture(this.resources.items[`image${this.raycaster.objectClicked.object.material.uniforms.uImageIndex.value}Texture`], this.raycaster.objectClicked.object.material.uniforms.uImageIndex.value)
								}
							},
							onComplete: () => 
							{
								this.projectTextAnimation = true
								this.raycaster.objectClicked = null
								this.clickedImage = null
								this.scroll.scrolling = false
							}
						})
					}
					else
					{
						this.raycaster.objectClicked = null
					}
				}

				if(this.raycasterCenterImage && !this.ending)
				{
					this.raycasterCenterImage.update()

					if(!this.scroll.scrolling && this.raycasterCenterImage.currentIntersect && this.image.material.uniforms.uImageIndex.value !== this.raycasterCenterImage.currentIntersect.object.material.uniforms.uImageIndex.value)
					{
						if(this.projectTextAnimation)
						{
							gsap.to(`.project-${this.selectedProject} .char-text`, {
								y: '-150%',
								ease: 'customEase',
								stagger: {
									amount: .1
								}
							})
							gsap.to(`.project-${this.selectedProject} .word-text`, {
								y: '-150%',
								ease: 'customEase',
								stagger: {
									amount: .3
								},
								onStart: () =>
								{
									this.prevSelectedProject = this.selectedProject
									this.selectedProject = this.raycasterCenterImage.currentIntersect.object.material.uniforms.uImageIndex.value
									document.querySelectorAll('.project-link').forEach(link =>
									{
										link.href = ProjectsDescriptions.projectsDescriptions[this.selectedProject].link
									})
									gsap.fromTo(`.project-${this.selectedProject} .char-text`, 
									{
										y: '150%'
									},
									{
										y: '0',
										ease: 'customEase',
										stagger: {
											amount: .1
										}
									})
									gsap.fromTo(`.project-${this.selectedProject} .word-text`, 
									{
										y: '150%'
									},
									{
										y: '0',
										ease: 'customEase',
										stagger: {
											amount: .3
										}
									})
								},
								onComplete: () =>
								{
									this.projectTextAnimation = true
								},
							})
						}
						this.projectTextAnimation = false
						
						this.image.updateTexture(this.resources.items[`image${this.raycasterCenterImage.currentIntersect.object.material.uniforms.uImageIndex.value}Texture`], this.raycasterCenterImage.currentIntersect.object.material.uniforms.uImageIndex.value)
					}

					if(!this.scroll.scrolling && this.raycasterCenterImage.currentIntersect)
					{
						gsap.to(this.raycasterCenterImage.currentIntersect.object.material.uniforms.uIsColored, {
							value: 1.0
						})
						gsap.to(this.raycasterCenterImage.currentIntersect.object.material.uniforms.uOpacity, {
							value: 1.0
						})
					}

					if(this.raycasterCenterImage.currentIntersect)
					{
						this.projectsImages.forEach((image) => 
						{
							if(image.mesh !== this.raycasterCenterImage.currentIntersect.object)
							{
								gsap.to(image.material.uniforms.uIsColored, {
									value: 0.0,
									duration: 1
								})
								gsap.to(image.material.uniforms.uOpacity, {
									value: 0.5,
									duration: 1
								})
							}
						})
					}
				}

				// Looping through every Image object to create the infinite scroll
				this.projectsImages.forEach((image, index) => 
				{
					image.mesh.position.y = (-index * this.projectsImagesMargin - this.scroll.currentScroll + 15000 * this.sliderHeight) % this.sliderHeight - (this.projectsImagesMargin * this.selectedProjectIndex)
				})

			}

			this.scroll.update()
		}
	}
}