import { gsap } from 'gsap'
import { CustomEase } from 'gsap/all'
import 'splitting/dist/splitting.css'
import 'splitting/dist/splitting-cells.css'
import Splitting from 'splitting'
import Experience from '../Experience.js'
import Raycaster from '../Utils/Raycaster.js'
import Image from './Image.js'
import vertexShader from '../shaders/about/vertex.glsl'
import fragmentShader from '../shaders/about/fragment.glsl'

export default class About
{
    constructor()
    {
        // Setup
		this.experience = new Experience()
		this.scene = this.experience.scene
		this.resources = this.experience.resources
		this.camera = this.experience.camera
		gsap.registerPlugin(CustomEase)
		CustomEase.create('customEase', '0.23, 1, 0.32, 1')
        this.ending = false
        this.ended = false

        this.imageSize = {
			width: 2.5 * (4/3),
			height: 2.5 * (4/3)
		}
		this.image = new Image(this.resources.items['pictureTexture'], this.imageSize, 0, vertexShader, fragmentShader, 0)
		this.image.material.uniforms.uOpacity.value = 0
		this.image.mesh.position.x = -((this.camera.width / 2) - (this.imageSize.width / 2) - (this.camera.width / 100) * 8.59375)
		this.image.mesh.position.z = 2

        document.querySelector('nav').insertAdjacentHTML(
			'afterend',
			'<div class="about"><h1 class="intro">Hi, I\'m Dorian Cottin</h1><p class="desc">I\'m a Frontend Developer from Lille, in France. I fell in love with web development in 2020 and decided to make it my job.<br/><br/>I specialize in bringing websites to life. With a keen eye for design and a passion for creating intuitive user experiences, I strive to create visually stunning and highly functional websites that leave a lasting impression on visitors. In short, I love using my skills to help businesses and organizations create websites that truly stand out in today\'s digital landscape.</p></div>'
		)
        Splitting({target: '.intro', by: 'chars'})
        Array.from(Splitting({target: '.desc', by: 'words'})[0].words).forEach((el) =>
        {
            el.innerHTML = `<span class="word-text">${el.innerHTML}</span>`
        })

        if(this.experience.isAboutOpen)
        {
            gsap.to(this.image.material.uniforms.uOpacity, {
                value: 0.5,
                ease: 'customEase',
                onComplete: () =>
                {
                    this.raycaster = new Raycaster([this.image.mesh], this.camera.instance)
                }
            })

            gsap.to('.about .intro .char', {
                y: 0,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            })
            gsap.to('.about .desc .word-text', {
                y: 0,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            })

            this.experience.isAboutOpen = false
        }
        else
        {
            gsap.to(this.image.material.uniforms.uOpacity, {
                value: 0.5,
                delay: .25,
                ease: 'customEase',
                onComplete: () =>
                {
                    this.raycaster = new Raycaster([this.image.mesh], this.camera.instance)
                }
            })

            gsap.to('.about .intro .char', {
                y: 0,
                delay: .25,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            })
            gsap.to('.about .desc .word-text', {
                y: 0,
                delay: .25,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            })
        }
    }

    end()
    {
        this.ending = true

        if(document.querySelector('.about'))
        {
            gsap.to('.about .intro .char', {
                y: '-150%',
                ease: 'customEase',
            })
            gsap.to('.about .desc .word-text', {
                y: '-150%',
                ease: 'customEase',
                onComplete: () =>
                {
                    if(document.querySelector('.about'))
                    {
                        document.querySelector('.about').remove()
                    }
                }
            })
        }

        gsap.to(this.image.material.uniforms.uOpacity, {
            value: 0.0,
            ease: 'customEase',
            onComplete: () =>
            {
                this.ended = true
            }
        })
    }

    destroy()
    {
        this.experience.destroy()
    }

    update()
    {
        if(this.image)
        {
            if(this.raycaster)
            {
                this.raycaster.update()

                if(this.raycaster.currentIntersect)
                {
                    this.image.mouse.x = this.raycaster.currentIntersect.uv.x
                    this.image.mouse.y = -(this.raycaster.currentIntersect.uv.y - 1)

                    gsap.to(this.image.material.uniforms.uOpacity, {
                        value: 1.0
                    })
                }
                else if(!this.ending)
                {
                    gsap.to(this.image.material.uniforms.uOpacity, {
                        value: 0.5
                    })
                }
            }

            this.image.update()
        }
    }
}