import { gsap } from 'gsap'
import { CustomEase } from 'gsap/all'
import Experience from '../Experience.js'
import Slider from './Slider.js'
import Projects from './Projects.js'
import About from './About.js'
import Splitting from 'splitting'

export default class Home
{
    constructor(about)
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.camera = this.experience.camera
        this.isAboutOpen = false 
        gsap.registerPlugin(CustomEase)
        CustomEase.create('customEase', '0.23, 1, 0.32, 1')

        // Check to see the current device
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
        {
            this.isMobile = true
            if(about)
            {
                about = false
            }
        }
        else
        {
            this.isMobile = false
        }

        // Home button interaction
        this.homeButtonTimeline = gsap.timeline({paused: true})

        this.homeButtonTimeline.to('nav .logo a svg path', {
            fill: 'rgb(255, 255, 255)',
            ease: 'customEase',
            stagger: {
                amount: .3
            }
        })

        window.addEventListener('keydown', (e) =>
        {
            if(this.projects)
            {
                if(e.key === 'Escape')
                {
                    this.slider.preventClicking = true
                    setTimeout(() =>
                    {
                        this.slider.preventClicking = false
                    }, 1000)
                    this.projects.end()
                }
            }
        })

        document.querySelector('nav .logo a').addEventListener('click', (e) => {
            e.preventDefault()
            if(!this.projects)
            {
                gsap.to('nav .transition', {
                    display: 'block',
                    duration: 0,
                    onComplete: () =>
                    {
                        gsap.to('nav .transition', {
                            opacity: 1,
                            ease: 'customEase',
                            onComplete: () => 
                            {
                                window.location.href = '/'
                            }
                        })
                    }
                })
            }
            else
            {
                if(this.projects.isAboutOpen)
                {
                    gsap.to('nav .transition', {
                        display: 'block',
                        duration: 0,
                        onComplete: () =>
                        {
                            gsap.to('nav .transition', {
                                opacity: 1,
                                ease: 'customEase',
                                onComplete: () => 
                                {
                                    window.location.href = '/'
                                }
                            })
                        }
                    })
                }
                this.slider.preventClicking = true
                setTimeout(() =>
                {
                    this.slider.preventClicking = false
                }, 1000)
                this.projects.end()
            }
        })

        // Menu button interaction
        Splitting({target: '.button', by: 'chars'})

            // Open button interaction
            this.openButtonTimeline = gsap.timeline({paused: true})

            this.openButtonTimeline.to('nav .about-button-container .about-button:first-of-type .char', {
                y: '-100%',
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            this.openButtonTimeline.to('nav .about-button-container .about-button:last-of-type .char', {
                y: '-100%',
                delay: .15,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            // Close button interaction
            this.closeButtonTimeline = gsap.timeline({paused: true})

            this.closeButtonTimeline.to('nav .close-button-container .close-button:first-of-type .char', {
                y: '-100%',
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            this.closeButtonTimeline.to('nav .close-button-container .close-button:last-of-type .char', {
                y: '-100%',
                delay: .15,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

        // Links button interaction
        Splitting({target: '.link', by: 'chars'})

            // Instagram button interaction
            this.instagramLinkTimeline = gsap.timeline({paused: true})

            this.instagramLinkTimeline.to('nav .links-container .link-container:first-of-type .link:first-of-type .char', {
                y: '-100%',
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            this.instagramLinkTimeline.to('nav .links-container .link-container:first-of-type .link:last-of-type .char', {
                y: '-100%',
                delay: .15,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            // Email button interaction
            this.emailLinkTimeline = gsap.timeline({paused: true})

            this.emailLinkTimeline.to('nav .links-container .link-container:last-of-type .link:first-of-type .char', {
                y: '-100%',
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)

            this.emailLinkTimeline.to('nav .links-container .link-container:last-of-type .link:last-of-type .char', {
                y: '-100%',
                delay: .15,
                ease: 'customEase',
                stagger: {
                    amount: .3
                }
            }, 0)


        // Wait for resources
        this.resources.on('loading', () =>
        {
            gsap.to('nav .logo a svg path', {
                opacity: 1,
                delay: .2,
                ease: 'customEase',
                stagger: {
                    amount: .5
                },
                onComplete: () =>
                {
                    gsap.to('nav .logo a svg path', {
                        fill: 'rgb(136, 136, 136)',
                        ease: 'customEase'
                    })

                    let logoSize
                    if(this.isMobile)
                    {
                        logoSize = '3.75vh'
                    }
                    else
                    {
                        logoSize = '2rem'
                    }

                    gsap.to('nav .logo a', {
                        top: '1rem',
                        left: '1rem',
                        transform: 'translate(0)',
                        width: logoSize,
                        height: logoSize,
                        ease: 'customEase',
                        onComplete: () =>
                        {
                            if(about)
                            {
                                this.isAboutOpen = true
                                this.about = new About()

                                gsap.set('nav .about-button-container', {
                                    y: '-105%'
                                })

                                gsap.set('nav .close-button-container', {
                                    y: '-100%'
                                })
                            }

                            gsap.to('nav .logo a', {
                                pointerEvents: 'all'
                            })

                            document.querySelector('nav .logo a').addEventListener('mouseover', () => {
                                this.homeButtonTimeline.play()
                            })

                            document.querySelector('nav .logo a').addEventListener('mouseleave', () => {                                
                                this.homeButtonTimeline.reverse()
                            })

                            gsap.to('nav .button', {
                                opacity: 1,
                                ease: 'customEase'
                            })

                            gsap.to('nav .link', {
                                opacity: 1,
                                ease: 'customEase',
                                onComplete: () => 
                                {
                                    if(document.querySelector('nav .about-button-container'))
                                    {
                                        document.querySelector('nav .about-button-container').addEventListener('click', (e) => 
                                        {
                                            e.preventDefault()
                                            document.title = 'About — Dorian Cottin'
                                            history.pushState({}, '', '/about')
    
                                            this.about = new About()
    
                                            gsap.to('nav .about-button-container', {
                                                y: '-105%',
                                                ease: 'customEase'
                                            })
    
                                            gsap.to('nav .close-button-container', {
                                                y: '-100%',
                                                ease: 'customEase'
                                            })
    
                                            this.isAboutOpen = true
    
                                            if(this.projects)
                                            {
                                                this.projects.isAboutOpen = true
    
                                                gsap.to(this.projects.image.material.uniforms.uOpacity, {
                                                    value: 0.0,
                                                    ease: 'customEase'
                                                })
    
                                                gsap.to(`.projects .project-${this.projects.selectedProject} .char-text`, {
                                                    y: '-150%',
                                                    ease: 'customEase'
                                                })
    
                                                gsap.to(`.projects .project-${this.projects.selectedProject} .word-text`, {
                                                    y: '-150%',
                                                    ease: 'customEase'
                                                })
    
                                                this.projects.projectsImages.forEach((image) =>
                                                {
                                                    gsap.to(image.material.uniforms.uOpacity, {
                                                        value: 0.0,
                                                        ease: 'customEase',
                                                        onComplete: () =>
                                                        {
                                                            gsap.to(image.material.uniforms.uOpacity, {
                                                                value: 0.0,
                                                                ease: 'customEase'
                                                            })
                                                        }
                                                    })
                                                })
                                            }
                                            else
                                            {
                                                this.slider.isAboutOpen = true
    
                                                this.slider.sliderImages.forEach((image) =>
                                                {
                                                    gsap.to(image.material.uniforms.uOpacity, {
                                                        value: 0.0,
                                                        ease: 'customEase'
                                                    })
                                                })
                                            }
    
                                            this.slider.preventClicking = true
                                        })

                                        document.querySelector('nav .close-button-container').addEventListener('click', (e) => 
                                        {
                                            e.preventDefault()
                                            document.title = 'Dorian Cottin'
                                            history.pushState({}, '', '/')

                                            gsap.to('nav .about-button-container', {
                                                y: '0%',
                                                ease: 'customEase'
                                            })

                                            gsap.to('nav .close-button-container', {
                                                y: '0%',
                                                ease: 'customEase'
                                            })

                                            this.isAboutOpen = false

                                            if(this.projects)
                                            {
                                                gsap.to(this.projects.image.material.uniforms.uOpacity, {
                                                    value: 1.0,
                                                    delay: .25,
                                                    ease: 'customEase',
                                                    onComplete: () =>
                                                    {
                                                        this.projects.isAboutOpen = false
                                                    }
                                                })

                                                gsap.fromTo(`.projects .project-${this.projects.selectedProject} .char-text`, 
                                                {
                                                    y: '150%'
                                                },
                                                {
                                                    y: '0',
                                                    ease: 'customEase',
                                                    stagger: {
                                                        amount: .3
                                                    }
                                                })

                                                gsap.fromTo(`.projects .project-${this.projects.selectedProject} .word-text`, 
                                                {
                                                    y: '150%'
                                                },
                                                {
                                                    y: '0',
                                                    ease: 'customEase',
                                                    stagger: {
                                                        amount: .3
                                                    }
                                                })

                                                this.projects.projectsImages.forEach((image) =>
                                                {
                                                    if(image.mesh !== this.projects.raycasterCenterImage.currentIntersect.object)
                                                    {
                                                        gsap.to(image.material.uniforms.uOpacity, {
                                                            value: 0.5,
                                                            delay: .25,
                                                            ease: 'customEase'
                                                        })
                                                    }
                                                    else
                                                    {
                                                        gsap.fromTo(image.material.uniforms.uOpacity, 
                                                        {
                                                            value: 0.0
                                                        },
                                                        {
                                                            value: 1.0,
                                                            delay: .25,
                                                            ease: 'customEase'
                                                        })
                                                        gsap.fromTo(image.material.uniforms.uIsColored, 
                                                        {
                                                            value: 0.0
                                                        },
                                                        {
                                                            value: 1.0,
                                                            delay: .25,
                                                            ease: 'customEase'
                                                        })
                                                    }
                                                })
                                            }
                                            else
                                            {
                                                this.slider.sliderImages.forEach((image) =>
                                                {
                                                    gsap.to(image.material.uniforms.uOpacity, {
                                                        value: 0.5,
                                                        delay: .25,
                                                        ease: 'customEase',
                                                        onComplete: () =>
                                                        {
                                                            this.slider.isAboutOpen = false
                                                        }
                                                    })
                                                })
                                            }

                                            this.slider.preventClicking = false
                                        })

                                        document.querySelector('nav .about-button-container').addEventListener('mouseover', () => 
                                        {
                                            this.openButtonTimeline.play()
                                        })

                                        document.querySelector('nav .about-button-container').addEventListener('mouseleave', () => 
                                        {
                                            this.openButtonTimeline.reverse()
                                        })

                                        document.querySelector('nav .close-button-container').addEventListener('mouseover', () => 
                                        {
                                            this.closeButtonTimeline.play()
                                        })

                                        document.querySelector('nav .close-button-container').addEventListener('mouseleave', () => 
                                        {
                                            this.closeButtonTimeline.reverse()
                                        })
                                    }

                                    document.querySelector('nav .link-container:first-of-type').addEventListener('mouseover', () => 
                                    {
                                        this.instagramLinkTimeline.play()
                                    })

                                    document.querySelector('nav .link-container:first-of-type').addEventListener('mouseleave', () => 
                                    {
                                        this.instagramLinkTimeline.reverse()
                                    })

                                    document.querySelector('nav .link-container:last-of-type').addEventListener('mouseover', () => 
                                    {
                                        this.emailLinkTimeline.play()
                                    })

                                    document.querySelector('nav .link-container:last-of-type').addEventListener('mouseleave', () => 
                                    {
                                        this.emailLinkTimeline.reverse()
                                    })
                                }
                            })

                            if(!this.isMobile)
                            {
                                this.slider = new Slider()

                                if(about)
                                {
                                    this.slider.isAboutOpen = true
                                }
                            }
                            else
                            {
                                document.querySelector('.buttons-container').remove()

                                gsap.set('nav .links-container', {
                                    top: '1rem',
                                    bottom: 'auto',
                                    gap: '1rem'
                                })

                                gsap.set('nav .links-container .link-container', {
                                    height: '1.3vh'
                                })

                                gsap.set('nav .links-container .link-container .link', {
                                    fontSize: '1.640625vh'
                                })

                                document.querySelector('nav').insertAdjacentHTML(
                                    'afterend',
                                    '<main><h2>[Visit on desktop for the full experience]</h2><h1>Hi, I\'m Dorian Cottin</h1><p>I\'m a Frontend Developer from Lille, in France. I fell in love with web development in 2020 and decided to make it my job.<br/><br/>I specialize in bringing websites to life. With a keen eye for design and a passion for creating intuitive user experiences, I strive to create visually stunning and highly functional websites that leave a lasting impression on visitors. In short, I love using my skills to help businesses and organizations create websites that truly stand out in today\'s digital landscape.</p></main>'
                                )

                                Splitting({target: 'main h2', by: 'chars'})
                                Splitting({target: 'main h1', by: 'chars'})
                                Array.from(Splitting({target: 'main p', by: 'words'})[0].words).forEach((el) =>
                                {
                                    el.innerHTML = `<span class="word-text">${el.innerHTML}</span>`
                                })

                                gsap.to('main h2 .char', {
                                    y: 0,
                                    ease: 'customEase',
                                    stagger: {
                                        amount: .3
                                    }
                                })
                                gsap.to('main h1 .char', {
                                    y: 0,
                                    ease: 'customEase',
                                    stagger: {
                                        amount: .3
                                    }
                                })
                                gsap.to('main p .word-text', {
                                    y: 0,
                                    ease: 'customEase',
                                    stagger: {
                                        amount: .3
                                    }
                                })
                            }
                        }
                    })
                }
            })
        })
    }

    destroy()
    {
        this.experience.destroy()
    }

    update()
    {
        if(this.slider && !this.projects)
        {
            this.slider.update()

            if(this.slider.ended && !this.projects)
            {
                this.projects = new Projects(this.slider.imagesPos, this.slider.clickedImageIndex)
            }
        }

        if(this.projects)
        {
            this.projects.update()

            if(this.projects.ending)
            {
                this.slider.preventScroll = true

                this.projects.projectsImages.forEach((image) => 
                {
                    gsap.to(image.material.uniforms.uOpacity, {
                        value: 0.0,
                        ease: 'customEase'
                    })
                })
            }

            if(this.projects.ended)
            {
                this.slider.executed = false
                this.slider.ended = false

                setTimeout(() =>
                {
                    this.slider.scroll.scroll = 0
                    this.slider.scroll.scrollTarget = 0
                    this.slider.scroll.currentScroll = 0
                    this.slider.preventScroll = false
                }, 500)

                this.slider.sliderImages.forEach((image) => 
                {
                    gsap.to(this.projects.image.material.uniforms.uOpacity, {
                        value: 0.0
                    })

                    gsap.to(image.material.uniforms.uIsColored, {
                        value: 0.0,
                        duration: 0
                    })

                    gsap.to(image.material.uniforms.uOpacity, {
                        value: 0.5
                    })
                })

                this.projects = null
            }
        }

        if(this.about)
        {
            this.about.update()

            if(!this.isAboutOpen)
            {
                this.about.end()
                
                if(this.about.ended)
                {
                    this.about = null
                }
            }
        }
    }
}